/**
 * New and rewritten script
 */

// Override Placeholder's behavior
// NOTE : this shouldn't exist
(function () {

    'use strict';

    function togglePH() {

        var ph = 'placeholder',
            dp = 'data-placeholder',
            attr = this.getAttribute(ph) ? ph : dp,
            other = this.getAttribute(ph) ? dp : ph,
            placeholder = this.getAttribute(attr);

        this.removeAttribute(attr);
        this.setAttribute(other, placeholder);
    }

    function bind() {

        var field = document.getElementById('lookup_card');

        if (field) {

            field.addEventListener('focus', togglePH);
            field.addEventListener('blur', togglePH);
        }
    }

    window.addEventListener('load', bind);
}());

/**
 * Old script
 */

/**
 * Formater un prix
 * Extension de l'objet Number de JavaScript
 *
 * (Number).formatMoney(nbr_decimals, decimals_sep, thousands_sep, currency_sym);
 *
 * nbr_decimals  (Number) = Nombre de d?imales apr? la virgule
 * decimals_sep  (String) = Symbole de s?aration des d?imales
 * thousands_sep (String) = Symbole de s?aration des milliers
 * currency_sym  (String) = Symbole de la devise
 */
(function (Number) {

    'use strict';

    var formatMoney;

    formatMoney = function(c, d, t, m) {

        var n = this,
            c = isNaN(c = Math.abs(c)) ? 2 : c,
            d = d == undefined ? "." : d,
            t = t == undefined ? "," : t,
            s = n < 0 ? "-" : "",
            i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
            j = (j = i.length) > 3 ? j % 3 : 0;

        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "") + ' ' + m;
    };

    Number.prototype.formatMoney = formatMoney;
}(Number));

function Gestion_page_accueil(){
    $("#gencode_card").focus();
}

// Page Achat
function Gestion_page_achat() {

    var $form = $(document.getElementById('achat_form'));

    // Submit du formulaire d? la s?ection d'un moyen de paiement
    $('.radio_submit', $form).on('change', function (e) {
        $form.submit();
    });

    // V?ification du prix entr??l'envoi du formulaire
    $form.on('submit', function () {

        var montant = Number($('#montant_remise').val()),
            is_checked_val = $('#is_checked_val').val(),
            montant_a = Number($('#montant').val()),
            montant_r = montant,
            ret = true;

        $('.message_erreur').hide();

        if (montant_a < montant_r) {

            showError('Veuillez entrer un montant remis&eacute; inf&eacute;rieur ou &eacute;gal au montant des achats');

            ret = false;
        } else if (is_checked_val == 'false' && (montant == 0 || montant > 200)) {

            $('.popin_pricetag', '#verifprice').html(montant.formatMoney(2, ',', ' ', '&euro;'));

            openPop('verifprice');

            ret = false;
        }

        return ret;
    });

    // Remise ?0 du champs "montant" si sa valeur est ?ale ?0
    $("#montant, #montant_remise").on({

        focus : function () {

            if ($(this).val() == 0) {

                $(this).val('');
            }
        },

        blur : function () {

            if ($(this).val() == '') {

                $(this).val('0');
            }
        }
    });

    // Le montant remis?ne peut pas ?re sup?ieur au montant des achats
    // $('#montant, #montant_remise').on('keyup focus blur', function () {

    //     var montant_a = Number($('#montant').val()),
    //         montant_r = Number($('#montant_remise').val());

    //     if (montant_a === 0 || montant_a < montant_r) {

    //         $('#montant_remise').val(montant_a);
    //     }
    // });
}

function focus_on(_this,check_item){
    var id = $(_this).parent().attr("id");
    focus_off();
    $(".boutons_radio","#"+id).css({"backgroundImage":"url(img/tati_privileges/bouton_select.jpg)","backgroundPosition":"top left"});
    $(".libelle_radio","#"+id).css({"color":"#fff"});
    if(check_item){
        $("input",".bloc_radios").removeAttr("checked");
        $("input."+id).attr("checked","checked");
    }
}

function focus_off(){
        $(".boutons_radio").css({"backgroundImage":"url(img/tati_privileges/bouton_select.jpg)","backgroundPosition":"bottom left"});
        $(".libelle_radio").css({"color":"#7c7c7c"});
}

function check_identification_form(form){

    var email_prefixe = $('#email_prefixe').val(),
        email_suffixe = $('#email_suffixe').val();

    if( email_prefixe == '' || email_suffixe == '' )
        return false;

    return true;
}

function check_info_form(form){

    var sexe_femme = $('#sexe_femme'),
        sexe_homme = $('#sexe_homme'),
        nom = $('#nom').val(),
        prenom = $('#prenom').val(),
        code_postal = $('#code_postal').val(),
        email = $('#email_cplt').val(),
        numero_mobile = $('#numero_mobile').val(),
        birthday_day = $('#birthday_day').val(),
        birthday_month = $('#birthday_month').val(),
        birthday_year = $('#birthday_year').val(),
        magasin = 1;


        var numero_debut = numero_mobile.substring(0, 2);
        var numero_fin = numero_mobile.substring(2, 10);

        var increment = 0;
        var max = 0;
        var numero_rep = 'zif';
        var longueur = 0;

    for(increment=0; increment<10; increment++ ){

        longueur = (numero_fin.split(increment).length - 1);

        if( longueur > max ){
            max = longueur;
            numero_rep = increment;
        }
    }

    if( $('#magasin').length > 1 ){
        magasin = $('#magasin').val();
    }

    if( magasin == 0 || ( !sexe_femme.is(":checked") && !sexe_homme.is(":checked") ) || nom == '' || prenom == '' || code_postal == '' || email == '' || numero_mobile == '' || birthday_day == '' || birthday_month == '' || birthday_year == '' ){
        alert("Veuillez remplir tous les champs marque d'une etoile");
        return false;
    }else if($('#numero_mobile').intlTelInput("isValidNumber") == false || $('#numero_mobile').intlTelInput("getNumberType") != 1) {
		alert("Numero de mobile incorrect");
        return false;
    }else{
        return true;
    }

}

function Gestion_page_infos(){

    var mem_radio = null,
        bouton_radio = $(".contenu_informations .bouton_radio"),
        bloc_radio = $(".contenu_informations .bloc_radios");

    $(bouton_radio,bloc_radio).click(function(){

        if(mem_radio!=this){

            mem_radio = this;

            $("span.boutons_radios",bloc_radio).css({"backgroundImage":"url(img/tati_privileges/btn_radio_off.jpg)"});
            $(".bouton_radio input").removeAttr("checked");

            $("span.boutons_radios",this).css({"backgroundImage":"url(img/tati_privileges/btn_radio_on.jpg)"});
            $("input."+this.id,bloc_radio).attr("checked","true");

        }
    });

    /* Saisie du code postal */
    var code_postal = $("#form_privilege #code_postal");
    var mem_codePostal = "";
    $(code_postal).keyup(function(){ /* !!! PAS de KEYPRESS ici !!! */
        var saisie_codePostal = $(this).val();
        if(isNaN(saisie_codePostal))
            $(this).val(mem_codePostal);
        else
            mem_codePostal = saisie_codePostal;
    });
    /* Pour eviter la saisie continue */
    $(code_postal).keypress(function(){
         if( isNaN($(this).val()) ){
              $(this).val(mem_codePostal);
         }
    });

    /* Saisie du num?ro de mobile */
    var num_mobile = $("#form_privilege #numero_mobile");
    var mem_num_mobile = "";
    $(num_mobile).keyup(function(){ /* !!! PAS de KEYPRESS ici !!! */
        var saisie_num_mobile = $(this).val();
		mem_num_mobile = saisie_num_mobile;
    });

    /* Saisie du num?ro de carte */
    var num_carte = $("#form_scanner_carte #gencode_card");
    var mem_num_carte = "";
    $(num_carte).keyup(function(){ /* !!! PAS de KEYPRESS ici !!! */
        var saisie_num_carte = $(this).val();
        if(isNaN(saisie_num_carte))
            $(this).val(mem_num_carte);
        else
            mem_num_carte = saisie_num_carte;
    });
    /* Pour eviter la saisie continue */
    $(num_carte).keypress(function(){
         if( isNaN($(this).val()) ){
              $(this).val(mem_num_carte);
         }
    });
}

function check_float(input, max) {

    var value = $(input).val().replace(',', '.'),
        old_value = value.substr(0, value.length - 1);

    $(input).val($(input).val());

    if( value != '-' && isNaN(value) && !isNaN(old_value) )
        $(input).val(old_value);
    else if( ( value != '-' && isNaN(value) && isNaN(old_value) ) || value === '' )
        $(input).val('');
    else if (value > max)
        $(input).val(max);
    else {

        if (value.match(/(\-)?(0[\d]+|[\d]+\.[\d]{3,})/))
            value = Number(Math.floor(value * 100) / 100);

        $(input).val(value);
    }

    if(
        $(input).attr('name') == 'montant_remise' ||
        $('#montant_remise').hasClass('hasnt_used_offer') ||
        $('#montant_remise').val() == old_value ||
        $('#montant_remise').val() == 0 ||
        $('#montant_remise').val() == ''
    ) {

        $('#montant_remise').val($(input).val());
    }
}

function check_numbers(input){

    var value = $(input).val(),
        old_value = value.substr(0, value.length-1);

    if( isNaN(value) && !isNaN(old_value) ){
        $(input).val(old_value);
    }else if( isNaN(value) && isNaN(old_value) ){
        $(input).val('');
    }
    else{
        $(input).val(value);
    }

}

function showError(error) {

    var img = $('<img />', {
            src : path_relative_root + 'img/mini_picto_panneau.png',
            alt : 'Erreur',
            width : '15',
            height : '14',
            style : 'vertical-align: -1px'
        }),
        txt = $('<span />').html(error),
        div = $('<div />').append(img).append(txt),
        met = $('.radio_submit:checked');

    met.prop('checked', false);

    $('#page_error_msg').html(div.html());

}

$(document).ready(function(){

    Gestion_page_accueil();

    Gestion_page_achat();

    Gestion_page_infos();

});

// TR cliquable
function onTRClick() {
    var link = $(this).find('.tr_link'),
        href = link.attr('href');

    window.location = href;
}

// AUTOCOMPLETE EMAIL
var old_val = '';

function purge_autocomplete_email() {

    var container = document.getElementById('mail_suggestions');

    while (container.childNodes.length)
        container.removeChild(container.childNodes[0]);
}

function navigate_autocomplete_email(key) {

    var container = document.getElementById('mail_suggestions'),
        children = document.getElementById('mail_suggestions').childNodes,
        active_el = container.getElementsByClassName('hover')[0],
        active_in;

    if (children.length > 0 && key === 38) {

        if (active_el) {

            active_el.className = '';

            active_in = children[Array.prototype.indexOf.call(children, active_el) - 1];

            if (active_in === undefined)
                active_in = active_el;

            active_in.className = 'hover';
        }
    } else if (children.length > 0 && key === 40) {

        if (!active_el) {

            (children[0]).className = 'hover';
        } else {

            active_el.className = '';

            active_in = children[Array.prototype.indexOf.call(children, active_el) + 1];

            if (active_in === undefined)
                active_in = active_el;

            active_in.className = 'hover';
        }
    }

    return false;
}

function suffixe_autocomplete_email() {

    var val = document.getElementById('email_prefixe').value;

    document.getElementById('email_prefixe').value = val.substr(0, val.length - 1);

    document.getElementById('mail_suggestions').style.display = 'none';
    purge_autocomplete_email();

    document.getElementById('hidden_selector').value = 'others';

    $(document.getElementById('hidden_selector')).change();
    $(document.getElementById('mail_suffixe_input')).focus();
}

function validate_autocomplete_email(e) {

    var mail = e.target.id,
        pref = document.getElementById('email_prefixe'),
        suff = document.getElementById('hidden_selector'),
        vSuf = document.getElementById('mail_suffixe_input');

    if (mail === undefined) {

        mail = document.getElementById('mail_suggestions').getElementsByClassName('hover')[0].id;
    }

    mail = mail.split('@');

    pref.value = mail[0];

    if (suff.innerHTML.indexOf('value="' + mail[1] + '"') > -1) {

        suff.value = mail[1];
    } else {

        suff.value = 'others';
    }

    $(suff).change(); // lame...

    vSuf.value = mail[1];

    document.getElementById('mail_suggestions').style.display = 'none';
    purge_autocomplete_email();
}

function autocomplete_email(val) {

    var time = new Date().getTime();

    old_val = val;

    $.ajax({
        url : 'privilege_caisse_autocomplete_email.html?' + time,
        type : 'post',
        data : { 'email' : val },
        success : function (e) {

            var state     = e.success,
                emails    = e.emails,
                container = document.getElementById('mail_suggestions'),
                increm    = 0,
                new_el, rex;

            if (state === 'fail') {

                purge_autocomplete_email();
            } else {

                purge_autocomplete_email();

                if( emails !== null ){

                    if (container.style.display !== 'block')
                        container.style.display = 'block';

                    rex = new RegExp($('#email_prefixe').val(), 'i');

                    for (increm; increm < emails.length; increm += 1) {

                        new_el = document.createElement('li');

                        new_el.id = emails[increm];
                        new_el.innerHTML = (emails[increm]).replace(rex, '<span class="pink">' + (emails[increm]).match(rex)[0] + '</span>');

                        new_el.addEventListener('click', validate_autocomplete_email);

                        container.appendChild(new_el);
                    }
                }else
                    container.style.display = 'none';
            }
        },
        error : function (e) {

            console.log(e);
        }
    });
}

function key_autocomplete_email(e) {

    var input = document.getElementById('email_prefixe'),
        liste = document.getElementById('mail_suggestions'),
        key = e.which;

    if( input.value.length > 1 && key == 48 ){
        suffixe_autocomplete_email();
        return;
    }

    if (input.value.length < 6) {

        old_val = input.value;

        if (liste.style.display !== 'none') liste.style.display = 'none';
        if (liste.childNodes.length > 0) purge_autocomplete_email();

        return;
    }

    switch (key) {

        case 13:
            validate_autocomplete_email();

        case 38: case 40:
            navigate_autocomplete_email(key);
            break;

        case 48:
            suffixe_autocomplete_email();
            break;

        default:
            if (input.value !== old_val) autocomplete_email(input.value);
    }
}

function checkMailSuffixe(val) {

    var select = $('#mail_suffixe_select'),
        input = $('#mail_suffixe_input');

    if (val === 'others') {

        select.fadeOut(200, function () {

            select.css('z-index', -100);
        });

        input.val('').css('z-index', 100).fadeIn(200);
    } else {

        input.val(val);
    }
}

function showSelect(val) {

    var select = $('#mail_suffixe_select'),
        option = $('#mail_suffixe_select').find('option[value="' + val + '"]'),
        input = $('#mail_suffixe_input');

    select.find('option').prop('selected', false);

    option = (option.length) ? option : select.find('option:first');

    option.prop('selected', true);
    select.find('select').change();

    input.fadeOut(200, function () {

        input.css('z-index', -100).val(select.find('select').val());
    });

    select.css('z-index', 100).fadeIn(200);
}

// Gestion des fen?res popin
(function () {

    'use strict';

    var openPop, closePop, modTicket, validPrice;

    /**
     * Ouvre la popin portant le nom donn?
     */
    openPop = function (name) {

        var target = $(document.getElementById(name));

        $('.new_popin', '#new_popin_shade').hide();

        if (target.length) {

            target.css('display', 'block');

            $('.new_popin_shade').fadeIn(200);
        }
    };

    /**
     * Ferme la popin portant le nom donn?si elle existe
     */
    closePop = function (name, uncheck) {

        var $popin = $(document.getElementById(name)).parent();

        if ($popin.length) {

            if (uncheck) {

                $('input[type="radio"]', '#radio_submit_wrapper').prop('checked', false);
            }

            $popin.fadeOut(160);
        }
    };

    /**
     * Force la modification du ticket
     */
    modTicket = function (value_force_update) {

        var new_input, form, method;

        new_input = $('<input />', {
            type : 'hidden',
            name : 'force_update',
            value : value_force_update
        });

        form = $('#achat_form');

        method = $('#paiement_method').val();
        method = $('input[value="' + method + '"]', form);

        form.append(new_input);
        method.prop('checked', true);

        form.submit();
    };

    /**
     * Confirmer un prix nul ou sup?ieur ?200?
     */
    validPrice = function () {

        $('#is_checked_val').val('true');

        $('#achat_form').submit();
    }

    // On rend les 3 m?hodes principales utilisable de l'ext?ieur
    window.openPop = openPop;
    window.closePop = closePop;
    window.modTicket = modTicket;
    window.validPrice = validPrice;
}());

function getMagasin() {
    if (navigator.onLine) {
        $.ajax({
            'type': 'POST',
            'url': path_relative_root + 'ajax_get_catalogue_magasin.php',
            'async': false,
            'success': function(response) {
                localStorage.setItem('TATI_CATALOGUE_MAGASIN', response);
            }
        });
    }
    globalMagasin = $.parseJSON(localStorage.getItem('TATI_CATALOGUE_MAGASIN'));

    for (var i = 0; i < globalMagasin.length; i++) {
        globalMagasin[i]['complet'] = globalMagasin[i]['nom'] + '(' + globalMagasin[i]['code'] + ') - ' + globalMagasin[i]['adresse'] + ' ' + globalMagasin[i]['ville'];
    }
}

function autoCompleteMagasin() {
    var value = $('#magasin').val();
    $('#autocomplete').html('');

    if (value != '') {
        for (var i = 0; i < globalMagasin.length; i++) {
            var found = false;

            for (var magasin in globalMagasin[i]) {
                if (magasin != 'id') {
                    if (globalMagasin[i][magasin].toLowerCase().indexOf(value.toLowerCase()) != -1) {
                        found = true;break;
                    }
                }
            }

            if (found) {
                var sContent = $('#autocomplete').html();
                var reValue = new RegExp("("+ value +")", "gi");

                sContent += '<li id="'+ globalMagasin[i]['id'] +'" onclick="setMagasin(this)">' + globalMagasin[i]['complet'].replace(reValue, '<strong>$1</strong>') + '</li>';

                $('#autocomplete').html(sContent);
            }
        }

        if ($('#autocomplete').html() != '') {
            $('#autocomplete').show();
            $('#id_magasin').val('');
        } else {
            $('#autocomplete').hide();
            $('#id_magasin').val('');
        }
    } else {
        $('#autocomplete').hide();
        $('#id_magasin').val('');
    }
}

function setMagasin(el) {
    var sMagasin = $('#magasin').val();
    var idLigne = el.id;
    var element = $('#' + idLigne);

    var i;
    for (i = 0; i < globalMagasin.length; i++) {
        if (globalMagasin[i]['id'] == idLigne) break;
    }

    if (i < globalMagasin.length){
        $('#magasin').val(globalMagasin[i]['complet']);
        $('#id_magasin').val(idLigne);
    }
    $('#autocomplete').hide();
}

$(document).on('click touchstart', '.results_row', onTRClick);

/* Uniquement Sur la Caisse */
if ($('.privileges_wrapper').length >= 0) {

    $(document).ready(function () {
        if(typeof $("#numero_mobile").val() != 'undefined'){
            $("#numero_mobile").intlTelInput({

                utilsScript: path_relative_root+'js/intlTelInputUtils.js?'+Math.floor(new Date().getTime() / 1000),
                numberType: "MOBILE",
            });
            $("#numero_mobile").change(function() {
                var numero_mobile = $(this).intlTelInput("getNumber");
                $("#numero_mobileHidden").val(numero_mobile);
            });
        }

        // Affichage du loader sur les boutons en disposant
        $('.btn_wrapper').on('click', '.button', function () {

            $(this).attr('disabled', true).next('.loader').fadeIn();
        });
    });
}
